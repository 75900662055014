import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Card, CardBody } from 'reactstrap';

const CategoryCardSkeleton = () => {
    return (
        <div className='manage-card'>
            <Card className='m-0'>
                <CardBody className='p-0'>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div className='d-flex p-1'>
                            <div className=''>
                                <Skeleton height={"55px"} width={'55px'} borderRadius={'50px'} />
                            </div>
                            <div className='ps-3 mt-3'>
                                <Skeleton height={"16px"} width={'120px'} />
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex p-1'>
                                <Skeleton height={"35px"} width={'110px'} borderRadius={'50px'} />
                            </div>
                            <div className='mt-3 ps-1 '>
                                <Skeleton height={"18px"} width={'18px'} />
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
};

export default CategoryCardSkeleton;
