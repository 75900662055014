import React, { useEffect, useState } from "react"
import StarRatings from "react-star-ratings"
import PrimaryButton from "components/Common/Buttons/PrimaryButton"
import SecountryButton from "components/Common/Buttons/SecountryBtn"
import usetAvather from "../../assets/images/svg/userAvather.svg"
import ReviewViewSkelrton from "components/Common/Skeleton/ReviewViewSkelrton"
import { useDispatch, useSelector } from "react-redux"
import { getReviewDetails, getReviewPublish } from "store/review/actions"

const ReviewView = ({ userId, toggle }) => {
  const review = useSelector(state => state.GetReviewDetails)
  const dispatch = useDispatch()

  useEffect(() => {
    if (userId) dispatch(getReviewDetails(userId))
  }, [userId])

  const handleUpdate = () => {
    dispatch(getReviewDetails(userId))
    toggle()
  }

  const publish = value => {
    const data = {}
    data["status"] = value
    dispatch(getReviewPublish(userId, data, handleUpdate))
  }

  const userInfo = useSelector(state => state.authInfo.data.user)
  const isAdvisor = userInfo?.role === "ADVISOR"
  return (
    <>
      {review?.loading === true ? (
        <ReviewViewSkelrton toggle={toggle} />
      ) : (
        <div>
          <div className="modal-header">
            <div className="head-item">
              <h1 className="page-title p-0">{"Review Details"}</h1>
              <span onClick={toggle} aria-hidden="true" className="close-icon">
                <i className="bx bx-x"></i>
              </span>
            </div>
          </div>
          <div className="modal-body">
            <div className="reviewDetails">
              {!isAdvisor && (
                <div>
                  <h6>Review for</h6>
                  <div className="info">
                    <img src={usetAvather} />
                    <p className="p-0">
                      {review?.data?.advisorId?.firstName}{" "}
                      {review?.data?.advisorId?.lastName}

                    </p>
                  </div>
                </div>
              )}
              {!isAdvisor && (
                <div>
                  <h6>Review by</h6>
                  <div className="info">
                    <img src={usetAvather} />
                    <p className="p-0">
                      {review?.data?.userId?.firstName}{" "}
                      {review?.data?.userId?.lastName}
                    </p>
                  </div>
                </div>
              )}
              {review?.data?.updatedAt && (
                <div className="date-details">
                  <h6>Reviewed On</h6>
                  <h6>
                    {new Date(review?.data?.updatedAt).toLocaleDateString()}
                  </h6>
                  <div className="info">
                    <p className="p-0">
                      {new Date(review?.data?.updatedAt).toLocaleTimeString()}
                    </p>
                  </div>
                </div>
              )}
            </div>
            {review?.data?.rating && (
              <div className="overRating">
                <h5>Overall Rating</h5>
                <StarRatings
                  rating={review?.data?.rating}
                  starEmptyColor="#d2d2d2"
                  starRatedColor="#ffd701"
                  numberOfStars={5}
                  name="rating"
                  starDimension="18px"
                  starSpacing="0"
                />
              </div>
            )}
            <div className="reViewContent">
              <h5>Comments</h5>
              <p className="p-0">{review?.data?.review}</p>
            </div>
          </div>
          {!isAdvisor && (
            <div className="modal-footer">
              <SecountryButton
                btnName="Un Publish"
                showIcon={false}
                btnType={"button"}
                onClick={() => {
                  toggle("unpublish")
                }}
              />
              <PrimaryButton
                btnName="Publish"
                showIcon={false}
                btnType={"button"}
                onClick={() => {
                  toggle()
                  publish("approved")
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ReviewView
