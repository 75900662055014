import { getDashBoardCount, getMarkettoMeter, postSuggestionType } from "helpers/backend_helper";
import { ADD_SUGGESTION_TYPE, GET_DASHBOARD_COUNT, GET_MARKETTO } from "./actionTypes";
import { addSuggestionTypeFail, addSuggestionTypeSuccess, getCountFail, getCountSuccess, getMarketto, getMarkettoFail, getMarkettoSuccess } from "./actions";
import { call, put, takeEvery } from "redux-saga/effects";
import toast from "react-hot-toast";

//Get Marketto Meter
function* fetchMarketto() {
    try {
        const response = yield call(getMarkettoMeter);
        yield put(getMarkettoSuccess(response))
    } catch (error) {
        yield put(getMarkettoFail(error))
    }
}
export function* MarkettoSaga() {
    yield takeEvery(GET_MARKETTO, fetchMarketto)
}

//Post Marketto Meter
function* postMarkettoSuggestionType({ payload: { suggestionType, afterSuccess, afterFail } }) {
    try {
        const response = yield call(postSuggestionType, suggestionType);
        console.log(response?.status);
        if (response.status === 'SUCCESS') {
            yield put(addSuggestionTypeSuccess(response));
            toast.success(response?.message)
            yield call(afterSuccess);
        } else {
            yield put(addSuggestionTypeFail(response));
            toast.error(response?.message)
            yield call(afterFail);
        }
    } catch (error) {
        yield put(addSuggestionTypeFail(error));
        yield call(afterFail);
    }
}
export function* suggestionTypeSaga() {
    yield takeEvery(ADD_SUGGESTION_TYPE, postMarkettoSuggestionType)
}

//get count 
function* fetchTotalCount() {
    try {
        const response = yield call(getDashBoardCount);
        yield put(getCountSuccess(response))
    } catch (error) {
        yield put(getCountFail(error))
    }
}
export function* TotalCountSaga() {
    yield takeEvery(GET_DASHBOARD_COUNT, fetchTotalCount)
}