import React, { useEffect, useMemo, useState } from 'react'
import { Container } from 'reactstrap'
import TableContainer from 'components/Common/TableContainer';
import TableSkeleton from 'components/Common/TableSkeleton';
import Skeleton from 'react-loading-skeleton';
import { UncontrolledTooltip } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFaq, changeFaqStatus, getAllFaq } from 'store/actions';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Confirmation from 'components/Common/Modals/Confirmation';


const FaqList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    document.title = "FAQ's | FiDeal AdminPanel";

    const faqList = useSelector(state => (state?.GetFaq?.faq?.faq));
    const faqLoading = useSelector(state => (state?.GetFaq?.loading));
    const faqReload = useSelector(state => (state?.DeleteFaqById));
    const faqstatusReload = useSelector(state => (state?.FaqStatus))

    const [activeModal, setActiveModal] = useState(false);
    const [isGetData, setIsData] = useState();
    const [deleteId, setDeleteId] = useState();
    const [faqToDelete, setFaqToDelete] = useState(false);


    //add  faq
    const addNewFaq = () => {
        history.push("/faq/add-new-faq");
    }

    //get all faq
    useEffect(() => {
        dispatch(getAllFaq())
    }, [])

    useEffect(() => {
        dispatch(getAllFaq())
    }, [faqstatusReload])

    //Delete Faq
    function deleteToggleModal() {
        setFaqToDelete(!faqToDelete);
    }
    const DeleteFaqAction = () => {
        dispatch(DeleteFaq(deleteId));
        setFaqToDelete(false);
    }
    useEffect(() => {
        if (faqReload?.faq?.status === "SUCCESS") {
            dispatch(getAllFaq());
        }
    }, [faqReload])
    const maxLength = 50;

    //active de-active
    const faqActiveEvent = () => {
        let status
        if (isGetData?.status === "INACTIVE") {
            status = "ACTIVE";
        } else {
            status = "INACTIVE";
        }
        dispatch(changeFaqStatus({ status: status }, isGetData?._id, history));
        setActiveModal(false);
    };

    const handleFaqId = (faqStatus) => {
        setIsData(faqStatus)
        setActiveModal(true);
    };

    //table 
    const columns = useMemo(
        () => [
            {
                Header: `S.No`,
                Cell: cellProps => {
                    return (
                        <div style={{ width: '100px' }}>
                            <p className='m-0'>{cellProps?.row?.index + 1}</p>
                        </div>
                    )
                }
            },
            {
                Header: 'Question',
                accessor: 'question'
            },
            {
                Header: 'Answer',
                Cell: cellProps => {
                    return (
                        <div>
                            <p className='m-0'>{cellProps.row.original?.answer.length > maxLength ? cellProps.row.original?.answer?.substring(0, maxLength) + "...." : cellProps.row.original?.answer}</p>
                        </div>
                    )
                }
            },
            // {
            //     Header: 'Created At',
            //     Cell: cellProps => {
            //         return (
            //             <div className='activity-added'>
            //                 {cellProps.row.original?.createdAt && <p className='m-0'>{moment(cellProps.row.original?.createdAt).format('LT')}<span> {moment(cellProps.row.original?.createdAt).format('LL')}</span></p>}

            //             </div>
            //         )
            //     }
            // },
            {
                Header: 'Status ',
                Cell: cellProps => {
                    return (
                        <div>
                            <div className="square-switch text-center d-flex justify-content-center"
                                onClick={() => {
                                    handleFaqId(cellProps.row.original);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    // eslint-disable-next-line react/no-unknown-property
                                    switch="bool"
                                    checked={cellProps.row.original.status === "ACTIVE" ? true : false}
                                />
                                <label
                                    htmlFor={`${cellProps.row.original._id}`}
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    className='m-0'
                                />
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center">
                            <Link
                                to={`/faq-details/${cellProps?.row?.original?._id}`}
                                className="view-faq-icon d-flex align-items-center">
                                <i className="mdi mdi-eye font-size-18" id="view-tooltip" />
                                <UncontrolledTooltip placement="top" target="view-tooltip">
                                    View
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to={`/faq/update-faq/${cellProps?.row?.original?._id}`}
                                className="edit-faq-icon d-flex align-items-center">
                                <i className="bx bx-edit-alt font-size-18 " id="edit-tooltip" />
                                <UncontrolledTooltip placement="top" target="edit-tooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Link>

                            <div
                                onClick={() => {
                                    const id = cellProps?.row?.original?._id;
                                    setDeleteId(id);
                                    deleteToggleModal();
                                }}
                                className="delete-faq-icon d-flex align-items-center">
                                <i className="bx bx-trash-alt font-size-18 " id="delete-tooltip" />
                                <UncontrolledTooltip placement="top" target="delete-tooltip">
                                    Delete
                                </UncontrolledTooltip>

                            </div>
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <div className='page-content faq'>
                <Container fluid>
                    <h1 className='page-title'>FAQ's</h1>
                    <div>
                        {faqLoading ?
                            <>
                                <div className='mb-2 p-3'>
                                    <Skeleton height={"53px"} width={'180px'} />
                                </div>
                                <TableSkeleton datacount={9} listcount={10} />
                            </> :

                            <TableContainer
                                columns={columns}
                                data={faqList && Array.isArray(faqList) ? faqList : []}
                                isGlobalFilter={false}
                                isAddOptions={true}
                                customPageSize={10}
                                handleOrderClicks={addNewFaq}
                                iSPageOptions={true}
                                className="custom-header-css"
                                btnname="Add New Faq "
                                btnType="button"
                            />}
                    </div>
                </Container>
                <Confirmation
                    showModal={faqToDelete}
                    toggle={deleteToggleModal}
                    primaryAction={DeleteFaqAction}
                    modalTitle={`Delete FAQ`}
                    secountBtn="Cancel"
                    primaryBtn="Delete"
                    description={`Are you sure you want to delete this FAQ?`}
                />
                <Confirmation
                    showModal={activeModal}
                    toggle={() => setActiveModal(false)}
                    primaryAction={faqActiveEvent}
                    modalTitle={`Confirmation!`}
                    primaryBtn={`${isGetData?.status === "INACTIVE" ? 'Activate' : 'Suspend'}`}
                    secountBtn="Cancel"
                    description={`Are you sure you want to ${isGetData?.status === "INACTIVE" ? 'activate' : 'suspend'} this Faq?`}
                />

            </div>
        </React.Fragment>
    )
}

export default FaqList