import React from 'react';
import { Modal } from 'reactstrap';
import dislike from '../../../assets/images/svgicons/dislike.svg';
import like from '../../../assets/images/svgicons/like.svg';
import moment from 'moment';

const ViewTips = ({ showModal, toggle, close, modalTitle, tipData }) => {
    return (
        <div>
            <Modal size="md" isOpen={showModal} centered={true}
                className='doc-modal confirmation'
                toggle={toggle}
            >
                <div className="modal-header px-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                        <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body px-0" style={{ maxHeight: "500px", overflow: "auto" }}>
                    <div className='tip-content'>
                        <div className='blog-header pb-2'>
                            <div className='d-flex w-100'>
                                <ul>
                                    <li>
                                        <div className='badge'>
                                            <p className={`stock-tiptype m-0 ${tipData?.stockTip?.toLowerCase()}`}>
                                                {tipData?.stockTip}
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <p className='m-0 text-capitalize main-cat'>{tipData?.category?.label}&nbsp;&nbsp;<span className='text-capitalize sub-cat'>{tipData?.subcategory?.label}</span></p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li className='d-flex'>
                                        <img src={like} alt='profile' className='me-2' />
                                        {tipData?.disLikesCount}</li>
                                    <li className='d-flex'>
                                        <img src={dislike} alt='profile' className='me-2' />
                                        {tipData?.likesCount}</li>
                                </ul>
                            </div>
                        </div>
                        <div className='descriptions'>
                            <div>
                                {
                                    tipData?.description?.map((item, index) => {
                                        return <>
                                            <h5 className={`py-2 mb-0`} key={item?._id} >{`${item?.description}`}</h5>
                                            <p className='date-time border-bottom'>
                                                {item?.updatedAt ? moment(item?.updatedAt).format('LT') : ""}{" "}{item?.updatedAt ? moment(item?.updatedAt).format('ll') : ""}
                                            </p>
                                        </>
                                    })
                                }
                            </div>
                        </div>
                        <ul className='tips-auth-info' style={{ width: "100%" }}>
                            <>
                                <li className='text-capitalize d-flex align-items-center'>
                                    <div className='rounded-circle ad-profile'>
                                        {tipData?.advisor?.profilePath ? <img src={tipData?.advisor?.profilePath} alt="profile-img" /> :
                                            <span>{tipData?.advisor?.firstName?.charAt(0).toUpperCase()}</span>
                                        }
                                    </div>
                                    <p className='ad-name'>{tipData?.advisor?.firstName + " " + tipData?.advisor?.lastName}</p>
                                </li>
                                {/* <li>
                                    <p className='m-0'>
                                        {tipData?.createdAt ? moment(tipData?.createdAt).format('LT') : ""}{" "}{tipData?.createdAt ? moment(tipData?.createdAt).format('ll') : ""}
                                    </p>
                                </li> */}
                            </>
                        </ul>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ViewTips;