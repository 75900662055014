import React, { useEffect, useState } from 'react';
import { approveOrRejectSignupRequest, getSubmittedAdvisorId } from 'store/actions';
import ViewAdvisorSkeleton from 'components/Common/Skeleton/ViewAdvisorSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, Container } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import ViewImage from 'components/Common/Modals/ViewImage';
import SignupRequestStatusChange from 'components/Common/Modals/SignupRequestStatusChange';
import PageLoader from 'components/Common/PageLoader';

const ViewSubmitted = () => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    document.title = "View Submitted MarketGenius | FiDeal AdminPanel"

    const request = useSelector(state => (state?.GetSubmittedById?.submittedList?.data));
    const requestLoading = useSelector(state => (state?.GetSubmittedById?.loading));
    const viewDataLoading = useSelector(state => (state?.GetAdvisorById?.loading));
    const updateDataLoading = useSelector(state => (state?.ApproveOrRejectSignupRequest?.loading));

    const [image, setImage] = useState("")
    const [viewImage, setViewImage] = useState(false)
    const [showRejectModel, setShowRejectModel] = useState(false)

    useEffect(() => {
        dispatch(getSubmittedAdvisorId(id));
    }, [id]);

    const handleViewImage = (path) => {
        setImage(path);
        setViewImage(true);
    }

    const toggleViewImage = () => {
        setImage("");
        setViewImage(!viewImage);
    }


    const handleUpdate = () => {
        history.push("/marketgenius")
    }

    const handleApprove = (value) => {
        const data = {}
        data["reqId"] = id
        data["reqStatus"] = value
        dispatch(approveOrRejectSignupRequest(data, handleUpdate))
    }

    const handleReject = () => {
        setShowRejectModel(true);
    }

    return (
        <React.Fragment>
            {requestLoading ? <PageLoader /> : ""}
            <div className="page-content view-advisor">
                <Container fluid>
                    <Breadcrumbs title="MarketGenius" dashurl="/marketgenius" breadcrumbItem={"MarketGenius Detail"} />

                    <div>
                        {viewDataLoading || updateDataLoading ? (
                            <ViewAdvisorSkeleton />
                        ) : (
                            <div className='manage-card'>
                                <Card className='p-0'>
                                    <CardBody className='p-0'>
                                        <div className='d-flex justify-content-between align-items-center p-3'>
                                            <div className='d-flex align-items-center p-1'>
                                                <div className='profile'>
                                                    <div className={`active-status`}></div>
                                                    <span>{request?.result?.firstName?.charAt(0)?.toUpperCase()}</span>
                                                </div>
                                                <div className='ps-3 name-head'>
                                                    <h5 className='m-0 text-capitalize'>{request?.result?.firstName}&nbsp;{request?.result?.lastName}</h5>
                                                    <span>{request?.result?.email}</span>
                                                </div>
                                            </div>
                                            <div className={`view-status}`}>
                                                {/* <span className={`text-capitalize`}> {request?.result?.status.toLowerCase()}</span> */}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='advisor-body'>
                                            <div className='crd-details'>
                                                <div>
                                                    <span>First Name</span>
                                                    <p>{request?.result?.firstName}</p>
                                                </div>
                                                <div>
                                                    <span>Last name</span>
                                                    <p>{request?.result?.lastName}</p>
                                                </div>
                                                <div>
                                                    <span>Mobile Number</span>
                                                    <p>{request?.result?.mobileNumber}</p>
                                                </div>
                                                <div>
                                                    <span>Description</span>
                                                    <p>{request?.result?.description}</p>
                                                </div>
                                            </div>

                                            <div className='crd-details'>
                                                <div>
                                                    <span>Id Type</span>
                                                    <p className='text-capitalize'>{request?.result?.selectedIdProof && request?.result?.selectedIdProof?.toLowerCase()?.replaceAll("_", " ")}</p>
                                                </div>
                                                <div>
                                                    <span>Id Number</span>
                                                    <p>{request?.result?.idNumber}</p>
                                                </div>
                                                <div>
                                                    <span>Address Proof Type</span>
                                                    <p className='text-capitalize'>{request?.result?.selectedAddressProof && request?.result?.selectedAddressProof?.toLowerCase()?.replaceAll("_", " ")}</p>
                                                </div>
                                                <div>
                                                    <span>Address</span>
                                                    <p>{request?.result?.address}</p>
                                                </div>

                                            </div>
                                            <div className='crd-details'>
                                                <div>
                                                    <span>SEBI number</span>
                                                    <p>{request?.result?.sebiNumber}</p>
                                                </div>
                                                <div>
                                                    <span>Id Proof Image</span>
                                                    <div className={`upload-field view-file justify-content-between`} >
                                                        <div className='d-flex align-items-center cursor-pointer' onClick={() => handleViewImage(request?.files?.idProofImage)}>
                                                            <i className='bx bxs-file-jpg h2 mb-0'></i>
                                                            <p className='mb-0'>ID proof</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>Address Proof Image</span>
                                                    <div className={`upload-field view-file justify-content-between`} >
                                                        <div className='d-flex align-items-center cursor-pointer ' onClick={() => handleViewImage(request?.files?.addressProofImage)}>
                                                            <i className='bx bxs-file-jpg h2 mb-0'></i>
                                                            <p className='mb-0'>Address proof</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>SEBI Certificate Image</span>
                                                    <div className={`upload-field view-file justify-content-between`} >
                                                        <div className='d-flex align-items-center cursor-pointer' onClick={() => handleViewImage(request?.files?.sebiCertificateImage)}>
                                                            <i className='bx bxs-file-jpg h2 mb-0'></i>
                                                            <p className='mb-0'>SEBI certificate</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div >
                                                    <span>Profile Image</span>
                                                    <div className={`upload-field view-file justify-content-between`} >
                                                        <div className='d-flex align-items-center cursor-pointer' onClick={() => handleViewImage(request?.files?.profileImage)}>
                                                            <i className='bx bxs-file-jpg h2 mb-0'></i>
                                                            <p className='mb-0'>Profile</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex pt-4'>
                                                <div className='pe-3'>
                                                    <PrimaryButton btnName="Approve" showIcon={false} btnType={'button'} onClick={() => handleApprove("APPROVED")} />
                                                </div>
                                                <SecountryBtn btnType='button' btnName="Reject" onClick={() => handleReject()} />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                        }
                        <ViewImage
                            modalTitle={"KYC view"}
                            path={image}
                            showModal={viewImage}
                            toggle={toggleViewImage}
                        />
                    </div>
                </Container>
            </div>
            <SignupRequestStatusChange
                modalTitle={"Reason for reject request"}
                showModal={showRejectModel}
                toggle={() => setShowRejectModel(false)}
                id={id}
            />
        </React.Fragment>
    );
};

export default ViewSubmitted;