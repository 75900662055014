
import React, { useEffect } from 'react';
import { Container } from 'reactstrap'
import ReviewCard from './ReviewCard';
import ReviewCardSkelrton from 'components/Common/Skeleton/ReviewCardSkelrton';
import { getReviewList } from 'store/review/actions';
import { useDispatch, useSelector } from 'react-redux';
import NoDataFound from 'components/Common/NoDataFound';
import Pagination from 'components/Common/pagination/Pagination';
import { dataPerPage } from 'constants/dataCount';
import { useState } from 'react';

const Review = () => {
    const dispatch = useDispatch();

    const review = useSelector(state => state?.GetReviewList?.data);
    const reviewLoading = useSelector(state => state?.GetReviewList);
    const totalCount = useSelector(state => (state?.GetReviewList?.data?.totalCount));
    const reviewPublish = useSelector(state => state.GetReviewPublish);
    const userInfo = useSelector(state => (state?.authInfo?.data?.user));
    const [currentPage, setCurrentPage] = useState(1);
    const getList = () => {
        dispatch(getReviewList(currentPage, dataPerPage))
    }

    useEffect(() => {
        getList();
    }, [currentPage])

    useEffect(() => {
        getList();
    }, [reviewPublish?.data])


    return (
        <React.Fragment>

            <div className='page-content review'>
                <Container fluid>
                    <div className='title-grp'>
                        <h1 className='page-title'>{
                            userInfo?.role === "SUPER_ADMIN" ? "View MarketGenius Rating Reviews" : "My Rating Reviews"
                        }</h1>
                    </div>
                    <div className='cardContent'>
                        {reviewLoading?.loading === true ? <>
                            {[...Array(9)].map((item, index) => {
                                return <ReviewCardSkelrton key={index} />;
                            })} </> : review?.ratings?.map((items, index) => {
                                return <ReviewCard items={items} key={index} />
                            })
                        }
                    </div>
                    {review?.ratings?.length === 0 && <NoDataFound />}
                    <div>{
                        totalCount > dataPerPage &&
                        <Pagination
                            className="pagination-bar custom-pagination"
                            currentPage={currentPage}
                            totalCount={totalCount}
                            pageSize={dataPerPage}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    }
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Review;