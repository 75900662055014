import React, { useEffect, useState } from 'react'; 
import Skeleton from "react-loading-skeleton";

const ReviewCardSkelrton = () => { 

    return (
        <React.Fragment>
            <div className='reviewCard'>
                <div className='header'>
                    <div className='avather'>
                        <Skeleton height={"23px"} width={'23px'} borderRadius={'50px'} />
                        <Skeleton className='ms-1' height={"20px"} width={'200px'} borderRadius={'0'} />
                    </div>
                    <div>
                        <Skeleton height={"20px"} width={'100px'} borderRadius={'0'} />
                    </div>
                </div>
                <div className='body-content'>
                    <Skeleton height={"65px"} width={'100%'} borderRadius={'0'} />
                    <Skeleton className='mt-3' height={"15px"} width={'96px'} borderRadius={'0'} />
                </div>
                <div className={'actionSection'}>
                    <Skeleton height={"30px"} width={'110px'} borderRadius={'25px'} />
                    <Skeleton className='ms-3' height={"30px"} width={'110px'} borderRadius={'25px'} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReviewCardSkelrton;
