import React, { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryButton from 'components/Common/Buttons/SecountryBtn';;
import Skeleton from "react-loading-skeleton";

const ReviewViewSkelrton = (toggle) => {

    return (
        <div>
            <div className="modal-header">
                <div className='head-item'>
                    <h1 className='page-title p-0'>{"Review"}</h1>
                    <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                </div>
            </div>
            <div className="modal-body">
                <div className='reviewDetails'>
                    <div>
                        <h6>Review for</h6>
                        <div className='info'>
                            <Skeleton height={"23px"} width={'23px'} borderRadius={'50px'} />
                            <Skeleton className={"ms-2"} height={"20px"} width={'100px'} borderRadius={'0'} />
                        </div>
                    </div>
                    <div>
                        <h6>Review by</h6>
                        <div className='info'>
                            <Skeleton height={"23px"} width={'23px'} borderRadius={'50px'} />
                            <Skeleton className={"ms-2"} height={"20px"} width={'100px'} borderRadius={'0'} />
                        </div>
                    </div>
                    <div>
                        <Skeleton className={"ms-2"} height={"20px"} width={'100px'} borderRadius={'0'} />
                        <div className='info'>
                            <Skeleton className={"ms-2"} height={"20px"} width={'100px'} borderRadius={'0'} />
                        </div>
                    </div>
                </div>
                <div className='overRating'>
                    <h5>Overall Rating</h5>
                    <Skeleton className={"ms-2"} height={"20px"} width={'100px'} borderRadius={'0'} />
                </div>
                <div className='reViewContent'>
                    <h5>Review</h5>
                    <Skeleton height={"120px"} width={'100%'} borderRadius={'0'} />
                </div>
            </div>
            <div className="modal-footer">
                <Skeleton height={"30px"} width={'110px'} borderRadius={'25px'} />
                <Skeleton className='ms-3' height={"30px"} width={'110px'} borderRadius={'25px'} />
            </div>
        </div>
    )
};

export default ReviewViewSkelrton;
