
//Auth
export const APPLICATION_LOGIN = "/auth/login";
export const FORGET_PASSWORD = "/admin/forgot-password-token";
export const FORGET_PASSWORD_RESEND_CODE = "/admin/forgot-password-resend-token";
export const RESET_PASSWORD_URL = "/admin/forgot-password-reset";

//Admin
export const GET_ALL_ADMIN = "/admin/get-all";
export const POST_NEW_ADMIN = "/admin/add";
export const UPDATE_ADMIN = "/admin";
export const GET_ADMIN_BY_ID = "/admin";
export const UPDATE_STATUS = "/admin/admin-status";

//Users
export const GET_ALL_USERS = "/admin/user/get-all";
export const ADD_NEW_USER = "/admin/user/add";
export const UPDATE_USER = "/admin/user";
export const GET_BY_USER_ID = "/admin/user";
export const USER_UPDATE_STATUS = "/admin/user/user-status";

//Manage Advisors
export const GET_MANAGE_ADVISORS = "/admin/advisor/get-all";
export const GET_ADVISOR_ID = "/admin/advisor";
export const ADD_NEW_ADVISOR = "/admin/advisor/add"
export const UPDATE_ADVISOR = "/admin/advisor"
export const ADVAICER_UPDATE_STATUS = "/admin/advisor/advisor-status";
export const GET_ACTIVE_ADVISORS = "/admin/advisor/get-by-status/0/0";
export const SUBMITTED_ADVISORS = "/admin/signup-request/pending";
export const GET_SUBMITTED_ADVISOR_ID = "/admin/signup-request";
export const APPROVE_OR_REJECT_SIGNUP_REQUEST = "/admin/signup-request/update-status";

//Manage Tips
export const GET_TIPS = "/admin/tips/get-all";
export const ADD_NEW_TIPS = "/admin/tips/add";
export const UPDATE_TIPS = "/admin/tips";
export const GET_BY_TIPS_ID = "/admin/tips";
export const CHANGE_TIPS_STATUS = "/admin/tips/tip-status";
export const CHANGE_TIP_ACTIVE_STATUS = "/admin/tips/status";
export const DELETE_TIPS = "/admin/tips";
export const GET_ALL_TIPTYPE = "/admin/tiptype/get-all";

//Category
export const GET_MAIN_CATEGORY = "/admin/category/get-all";
export const GET_SUBCATEGORY = "/admin/subcategory/get-by-category";
export const ADD_NEW_CATEGORY = "/admin/category/add";
export const UPDATE_CATEGORY = "/admin/category";
export const GET_BY_CATEGORY_ID = "/admin/category";
export const ADD_SUBCATEGORY = "/admin/subcategory/add";
export const GET_BY_SUBCATEGORY_ID = "/admin/subcategory";
export const UPDATE_SUBCATEGORY = "/admin/subcategory";
export const SUBCATEGORY_UPDATE_STATUS = "/admin/subcategory/subcategory-status";
export const CATEGORY_UPDATE_STATUS = "/admin/category/category-status";
export const UPDATE_DEFAULT_CATEGORY = "/admin/category/category-default";
export const UPDATE_DEFAULT_SUBCATEGORY = "/admin/subcategory/subcategory-default";

//Manage FAQ
export const GET_ALL_FAQ = "/admin/faq/get-all/0/0";
export const GET_FAQ_ID = "/admin/faq";
export const ADD_NEW_FAQ = "/admin/faq/add";
export const UPDATE_FAQ = "/admin/faq";
export const DELETE_FAQ = "/admin/faq";
export const FAQ_STATUS = "/admin/faq/faq-status"

//Generate password
export const GENERATE_PASSWORD = "/admin/user/reset-password-user";
export const UPDATE_PROFILE = "/admin/profile";
export const CHANGE_PASSWORD = "/admin/profile/change-pwd";

//update profile image
export const UPDATE_PROFILE_IMG = "/admin/user/update-profile-img";
export const GET_DECODE_IMAGE = "/admin/user/get-profile-img";

//settings
export const SETTINGS_UPDATE = "/admin/settings/enquiry";
export const SETTINGS_UPDATE_TOEMAIL = "/admin/settings/toemail";
export const GET_SETTINGS = "/admin/settings/get-all";
export const GET_NOTIFICATION = "/admin/settings/notification";
export const TRADINGMETER_STSTUS = "/admin/settings/trading";
export const SOCIAL_LINKS = '/admin/socialinfo/get-all';
export const UPDATE_SOCIAL_LINKS = '/admin/socialinfo/update';

//Roles
export const GET_ALL_ADMIN_ROLES = "/admin/roles/get-all";
export const ADMIN_ROLE_TYPE = "/admin/roles/get-by-";

//Review
export const GET_PENDING_REVIEWS = "admin/ratings-request";
export const GET_REVIEWS_DETAILS = "admin/ratings-request";

//Marketto
export const GET_MARKETTO_METER = "/marketto-meter/advisorslot"
export const ADD_SUGGESTION_TYPE = "/marketto-meter/add"
//close tip
export const CLOSE_TIP = "/admin/tips/update/tip-status"
export const TIP_TARGET_STATUS_CHANGE = "/admin/update/tip-target"

//getCount
export const GET_DASHBOARDTOTAL_COUNT="/admin/main/dashboard"
