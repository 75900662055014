import React, { useEffect, useState } from "react"
import StarRatings from "react-star-ratings"
import usetAvather from "../../assets/images/svg/userAvather.svg"
import PrimaryButton from "components/Common/Buttons/PrimaryButton"
import SecountryButton from "components/Common/Buttons/SecountryBtn"
import View from "../../assets/images/svg/eye.svg"
import { Modal } from "reactstrap"
import ReviewView from "./ReviewView"
import UnpublishDescription from "components/Common/Modals/UnpublishDescription"
import { useSelector } from "react-redux"

const ReviewCard = ({ items }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [userId, setUserId] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const toggle = data => {
    setShowDetails(data === "reason to unpublish")
    setShowModal(data === "unpublish")
  }

  const showDetailsHandler = id => {
    setShowDetails(true)
    setUserId(id)
  }

  const userInfo = useSelector(state => state.authInfo.data.user)
  const isAdvisor = userInfo?.role === "ADVISOR"

  return (
    <React.Fragment>
      <div className="reviewCard">
        <div className="header">
            {!isAdvisor && (
          <div className="avather">
              <>
                <img src={usetAvather} />
                <p className="name m-0">
                  {items?.advisorId?.firstName} {items?.advisorId?.lastName}
                </p>
              </>
          </div>
            )}
          <div>
            <StarRatings
              rating={items?.rating}
              starEmptyColor="#d2d2d2"
              starRatedColor="#ffd701"
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starSpacing="0"
            />
          </div>
        </div>
        <div className="body-content">
          <p className="textContent">{items?.review}</p>
          {!isAdvisor && (
            <p className="authDetails m-0">
              Post by{" "}
              <a href="#">
                {items?.userId?.firstName} {items?.userId?.lastName}
              </a>
            </p>
          )}
        </div>
        <div className={"actionSection"}>
          {userInfo?.role === "SUPER_ADMIN" ? (
            <PrimaryButton
              btnName=" Publish / Unpublish"
              showIcon={false}
              btnType={"button"}
              btnIcon={View}
              onClick={() => showDetailsHandler(items?._id)}
              customClass="greenButton"
            />
          ) : (
            <PrimaryButton
              btnName="View"
              showIcon={false}
              btnType={"button"}
              onClick={() => showDetailsHandler(items?._id)}
              customClass="greenButton"
            />
          )}
        </div>
      </div>
      <>
        <Modal
          isOpen={showDetails}
          centered={true}
          className="doc-modal confirmation review-model"
          toggle={toggle}
        >
          <ReviewView toggle={toggle} userId={userId} />
        </Modal>
        <UnpublishDescription
          showModal={showModal}
          toggle={toggle}
          modalTitle={"Reason for Unpublish"}
          id={userId}
        />
      </>
    </React.Fragment>
  )
}

export default ReviewCard
