import React, { useEffect, useState } from 'react'
import ReactSpeedometer, { CustomSegmentLabelPosition, Transition } from "react-d3-speedometer"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import { addSuggestionType, getCount, getMarketto } from 'store/dashboard/actions';
import accessToken from 'helpers/jwt-token-access/accessToken';
import DashboardListCard from 'components/Common/Cards/DashboardListCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import fullStar from "../../assets/images/svgicons/full_star.svg"
import superadmin from "../../assets/images/svgicons/superadmin.svg"
import admin from "../../assets/images/svgicons/admin.svg"
import advisor from "../../assets/images/svgicons/advisor.svg"
import pendingAdvisor from "../../assets/images/svgicons/pendingadvisor.svg"
import pendingreviews from "../../assets/images/svgicons/pendingreview.svg"
import user from "../../assets/images/svgicons/user.svg"
import reviews from "../../assets/images/svgicons/review.svg"
const Dashboard = () => {
    document.title = "Dashboard | FiDeal AdminPanel";

    const dispatch = useDispatch();
    
    const history = useHistory();

    const MarkettoAdvisor = useSelector(state => (state?.GetMarketto?.tradingMeter?.data));
    const GetalldashboardCount = useSelector(state => (state?.GetCount?.count?.data));
    const userInfo = useSelector(state => (state?.authInfo?.data?.user));
    const [speedometerValue, setSpeedometerValue] = useState(50);
    //percentagebar calculation
    const percentageOne = (GetalldashboardCount?.rating?.ratingCounts['1stars'] / GetalldashboardCount?.approvedRating?.count) * 100 || 0;
    const percentageTwo = (GetalldashboardCount?.rating?.ratingCounts['2stars'] / GetalldashboardCount?.approvedRating?.count) * 100 || 0;
    const percentageThree = (GetalldashboardCount?.rating?.ratingCounts['3stars'] / GetalldashboardCount?.approvedRating?.count) * 100 || 0;
    const percentageFour = (GetalldashboardCount?.rating?.ratingCounts['4stars'] / GetalldashboardCount?.approvedRating?.count) * 100 || 0;
    const percentageFive = (GetalldashboardCount?.rating?.ratingCounts['5stars'] / GetalldashboardCount?.approvedRating?.count) * 100 || 0;

    useEffect(() => {
        dispatch(getCount())
    }, [])

    useEffect(() => {
        if (userInfo?.role === "ADVISOR") {
            dispatch(getMarketto())
        }
    }, [userInfo?.role])

    const AdvisorList = () => {
        history.push(`/marketgenius`);
    }
    const PendingadvisorList = () => {
        history.push(`/marketgenius`);
    }
    const UserList = () => {
        history.push(`/users`);
    }
    const AdminList = () => {
        history.push(`/admin`);
    }
    const PendingReviewList = () => {
        history.push(`/reviews`);
    }
    const ApprovedReviewList = () => {
        history.push(`/reviews`);
    }

    useEffect(() => {
        if (MarkettoAdvisor?.tradingMeter?.suggestionType === 'BUY') {
            setSpeedometerValue(100);
        } else if (MarkettoAdvisor?.tradingMeter?.suggestionType === 'SELL') {
            setSpeedometerValue(0);
        } else if (MarkettoAdvisor?.tradingMeter?.suggestionType === 'NULL') {
            setSpeedometerValue(50);
        }
    }, [MarkettoAdvisor?.tradingMeter?.suggestionType])

    useEffect(() => {
        if (userInfo?.role === "ADVISOR") {
            const apiWorker = new Worker(URL.createObjectURL(new Blob([`
              self.addEventListener('message', async (event) => {
                if (event.data === 'start') {
                  setInterval(async function() {
                    try {
                      const headers = {
                        'Authorization': '${accessToken}',
                        'Content-Type': 'application/json',
                      };
                      const response = await fetch('${process.env.REACT_APP_BACKEND_URL}/marketto-meter/advisorslot', { headers });
                      const data = await response.json();
                      self.postMessage(data);
                    } catch (error) {
                      console.error('Error fetching API data:', error);
                    }
                  }, 30000);
                }
              });
            `], { type: 'application/javascript' })));

            apiWorker.postMessage('start');

            apiWorker.addEventListener('message', (event) => {
                dispatch(getMarketto());
            });

            return () => {
                apiWorker.terminate();
            };
        }
    }, [accessToken]);

    const afterSuccess = () => {
        dispatch(getMarketto());
    }

    const afterFail = () => {
        setSpeedometerValue(50)
    }

    const handleButtonClick = (suggestionType) => {
        const data = {};
        data['suggestionType'] = suggestionType;
        dispatch(addSuggestionType( data, afterSuccess, afterFail ));
        if (suggestionType === 'BUY') {
            setSpeedometerValue(100);
        } else if (suggestionType === 'SELL') {
            setSpeedometerValue(0);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content dashboard-page">
                <Container fluid>
                    <h1 className='page-title'>Dashboard</h1>
                    <>
                        {userInfo?.role === "ADVISOR" &&
                            <div className='card-design'>
                                <div>
                                    <DashboardListCard icon={reviews} title={GetalldashboardCount?.approvedRating?.label} count={GetalldashboardCount?.approvedRating?.count} onClick={ApprovedReviewList} />
                                </div>
                            </div>
                        }
                        {(userInfo?.role === "ADMIN" || userInfo?.role === "SUPER_ADMIN") &&
                            <div className='card-design'>
                                <div>
                                    <DashboardListCard  icon={advisor} title={GetalldashboardCount?.advisor?.label} count={GetalldashboardCount?.advisor?.count} onClick={AdvisorList} />
                                </div>
                                <div>
                                    <DashboardListCard icon={pendingAdvisor} title={GetalldashboardCount?.pendingAdvisor?.label} count={GetalldashboardCount?.pendingAdvisor?.count} onClick={PendingadvisorList} />
                                </div>
                                <div>
                                    <DashboardListCard icon={user} title={GetalldashboardCount?.user?.label} count={GetalldashboardCount?.user?.count} onClick={UserList} />
                                </div>
                                {userInfo?.role !== "ADMIN" &&
                                    <div>
                                        <DashboardListCard icon={admin} title={GetalldashboardCount?.admin?.label} count={GetalldashboardCount?.admin?.count} onClick={AdminList} />
                                    </div>
                                }
                                {userInfo?.role === "SUPER_ADMIN" &&
                                    <div>
                                        <DashboardListCard icon={superadmin} title={GetalldashboardCount?.superAdmin?.label} count={GetalldashboardCount?.superAdmin?.count} onClick={AdminList} />
                                    </div>
                                }
                                <div>
                                    <DashboardListCard icon={pendingreviews} title={GetalldashboardCount?.pendingRating?.label} count={GetalldashboardCount?.pendingRating?.count} onClick={PendingReviewList} />
                                </div>
                            </div>
                        }
                    </>

                    <>
                        <Row>
                            <Col lg={4}>
                                {userInfo?.role === "ADVISOR" ?
                                    <div className="card marketto">
                                        <h3 className='text-center'>Markettometer</h3>
                                        <div className="meter-header">
                                            {MarkettoAdvisor?.isSlotOpen === "YES" ? (
                                                <>
                                                    <p>Start: <span>{MarkettoAdvisor?.tradingMeter?.slotStartTime}</span></p>
                                                    <p>End: <span> {MarkettoAdvisor?.tradingMeter?.slotEndTime}</span></p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="text-center">{MarkettoAdvisor?.message}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="meter-chart">
                                            {/* <img src={meterPlaceholder} alt="meterplaceHolder" /> */}
                                            <ReactSpeedometer
                                                value={speedometerValue}
                                                width={300}
                                                height={150}
                                                segments={3}
                                                maxValue={100}
                                                needleTransitionDuration={2000}
                                                needleTransition={Transition.easeElasticIn}
                                                segmentColors={[
                                                    "#ff0000", "#32CD32", "#008000"
                                                ]}
                                                needleColor="steelblue"
                                                ringWidth={10}
                                                customSegmentStops={[0, 50, 100]}
                                                currentValueText=" "
                                                currentValuePlaceholderStyle={" "}
                                                customSegmentLabels={[
                                                    // {
                                                    //     text: "Strong Sell",
                                                    //     position: CustomSegmentLabelPosition.Outside
                                                    // },
                                                    {
                                                        text: "Sell",
                                                        position: CustomSegmentLabelPosition.Outside
                                                    },

                                                    {
                                                        text: "Buy",
                                                        position: CustomSegmentLabelPosition.Outside
                                                    },
                                                    // {
                                                    //     text: "Strong Buy",
                                                    //     position: CustomSegmentLabelPosition.Outside
                                                    // }

                                                ]}
                                            />

                                            <div className='d-flex align-items-center justify-content-between'>
                                                {MarkettoAdvisor?.isSlotOpen === "YES" && (
                                                    <>
                                                        <div className='sell-suggestion'>
                                                            <Button disabled={speedometerValue == 0 || speedometerValue == 100} onClick={() => handleButtonClick('SELL',)}>Sell</Button>
                                                        </div>
                                                        <div className='buy-suggestion '>
                                                            <Button disabled={speedometerValue == 0 || speedometerValue == 100} onClick={() => handleButtonClick('BUY')}>Buy</Button>

                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                        </div>
                                    </div> : ''
                                }
                            </Col>
                            <Col lg={8}>
                                {userInfo?.role === "ADVISOR" ?
                                    <div className="rating card">
                                        <h3 className='text-left'>Overall Rating</h3>
                                        <div className="star-list  star-5">
                                            <div className="count-star">
                                                <div>5</div>
                                                <div className="star-image">
                                                    <img src={fullStar} alt="star" />
                                                </div>
                                            </div>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: `${percentageFive}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="star-list  star-4">
                                            <div className="count-star">
                                                <div>4</div>
                                                <div className="star-image">
                                                    <img src={fullStar} alt="star" />
                                                </div>
                                            </div>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: `${percentageFour}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="star-list  star-3">
                                            <div className="count-star">
                                                <div>3</div>
                                                <div className="star-image">
                                                    <img src={fullStar} alt="star" />
                                                </div>
                                            </div>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: `${percentageThree}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="star-list  star-2">
                                            <div className="count-star">
                                                <div>2</div>
                                                <div className="star-image">
                                                    <img src={fullStar} alt="star" />
                                                </div>
                                            </div>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: `${percentageTwo}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="star-list  star-1">
                                            <div className="count-star">
                                                <div>1</div>
                                                <div className="star-image">
                                                    <img src={fullStar} alt="star" />
                                                </div>
                                            </div>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    style={{ width: `${percentageOne}%` }}
                                                ></div>
                                            </div>
                                        </div>

                                        <div className="total-rating">
                                            <div className="rating-count">
                                                <div className="overall-count">
                                                    <h1>{GetalldashboardCount?.rating?.avgRating || 0}</h1>
                                                    <div className="star-image">
                                                        <img src={fullStar} alt="star" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                                }
                            </Col>
                        </Row>

                    </>

                </Container>
            </div>
        </React.Fragment>

    )
}

export default Dashboard;